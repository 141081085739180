import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Icons from "../core/ui/images/icomoon/symbol-defs.svg";
import Meta from "../organisms/meta/meta";
import Navigation from "../organisms/navigation/Navigation";
import PageWrapper from "../organisms/page-wrapper/PageWrapper";
import useAxeptioScriptLoader from "../utils/hooks/useAxeptioScriptLoader";

const PrivacyPolicy = ({ data }) => {
  const { title, slug, content } = data.contentfulPrivacyPolicy;

  useAxeptioScriptLoader("en");

  return (
    <>
      <Meta
        title={title}
        description={title}
        slug={slug}
        lang={"en"}
        bodyClass={"tp-legaltext"}
        disableTranslations={false}
      />
      <PageWrapper>
        <Navigation />
        <main id="main" className="or-main">
          <h1>{title}</h1>
          {content && <div className="content">{renderRichText(content)}</div>}
        </main>
        <Icons />
      </PageWrapper>
    </>
  );
};
export default PrivacyPolicy;

export const query = graphql`
  query PrivacyPolicyQuery {
    contentfulPrivacyPolicy {
      title
      content {
        raw
      }
      slug
    }
  }
`;
