import { useEffect } from "react";

type AxeptioSettings = {
  clientId?: string;
  cookiesVersion: string;
  googleConsentMode: {
    default: {
      analytics_storage: string;
      ad_storage: string;
      ad_user_data: string;
      ad_personalization: string;
      functionality_storage: string;
      personalization_storage: string;
      security_storage: string;
      wait_for_update: number;
    };
  };
};

declare global {
  interface Window {
    axeptioSDK?: any;
    axeptioSettings?: AxeptioSettings;
  }
}

const useAxeptioScriptLoader = (langAttribute: string): void => {
  useEffect((): void => {
    if (window.axeptioSDK || !langAttribute) {
      return;
    }

    window.axeptioSettings = {
      clientId: process.env.GATSBY_AXEPTIO_CLIENT_ID,
      cookiesVersion: `products web-base-${langAttribute}`,
      googleConsentMode: {
        default: {
          analytics_storage: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 500,
        },
      },
    } as AxeptioSettings;

    const scriptTag: HTMLScriptElement = document.createElement("script");
    scriptTag.async = true;
    scriptTag.src = "//static.axept.io/sdk-slim.js";

    scriptTag.addEventListener("load", (): void => {
      window.axeptioSDK = true;
    });

    document.head.appendChild(scriptTag);
  }, [langAttribute]);
};

export default useAxeptioScriptLoader;
